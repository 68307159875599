'use client';

import { useEffect, useState } from 'react';
import Hero from '@/components/home/hero';
import { FAQ } from '@/components/home/faq';
import SocialLinks from '@/components/home/sociallinks';
import Problems from '@/components/home/problems';
import Benefits from '@/components/home/benefits';
import SocialProofCarousel from '@/components/home/socialproof-carousel';
import Pricing from '@/components/learn-more/pricing';
import IntroductionSection from '@/components/home/introductionsection';
import OthersCharges from '@/components/learn-more/otherscharge';
import PremiumBonuses from '@/components/home/premiumbonuses';
import Guarantee from '@/components/learn-more/guarantee';
import ClearPathLongWhite from '@/components/learn-more/clearpathwhite';
import NexusLongLight from '@/components/learn-more/nexuslonglight';
import SuccessFailureDialog from '@/components/learn-more/success-failure-dialog';
import ErrorDialog from '@/components/learn-more/ErrorDialog';
import CallToAction from '@/components/home/CallToAction';
import Countdown from '@/components/home/countdown';
import PromoBanner from '@/components/home/promo-banner';
export default function Home() {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successModal, setSuccessModal] = useState<boolean | string>('');
  const onError = (message: string) => {
    setErrorMessage(message);
    setIsErrorDialogOpen(true);
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Extract the search parameters from the URL
      const searchParams = new URLSearchParams(window.location.search);
      const success = searchParams.get('success');
      const failed = searchParams.get('failed');
      const error = searchParams.get('error');
      failed && error && onError(error);
      setSuccessModal(success ? true : failed ? false : '');
    }
  }, []); // Only run once on component mount

  return <div className="flex-auto " data-sentry-component="Home" data-sentry-source-file="page.tsx">
      <Hero data-sentry-element="Hero" data-sentry-source-file="page.tsx" />      
      <Countdown endDate="2024-11-17T17:11:59-06:00" message={"Bonus Intro 1-on-1 Call With Jordan Included With Any Package!\nThis Veteran's Day Promotion Ends In:"} data-sentry-element="Countdown" data-sentry-source-file="page.tsx" />
      <SocialProofCarousel data-sentry-element="SocialProofCarousel" data-sentry-source-file="page.tsx" />
      <Problems data-sentry-element="Problems" data-sentry-source-file="page.tsx" />
      <IntroductionSection data-sentry-element="IntroductionSection" data-sentry-source-file="page.tsx" />
      <CallToAction data-sentry-element="CallToAction" data-sentry-source-file="page.tsx" />
      <Benefits data-sentry-element="Benefits" data-sentry-source-file="page.tsx" />
      <PremiumBonuses data-sentry-element="PremiumBonuses" data-sentry-source-file="page.tsx" />
      <NexusLongLight data-sentry-element="NexusLongLight" data-sentry-source-file="page.tsx" />
      <ClearPathLongWhite data-sentry-element="ClearPathLongWhite" data-sentry-source-file="page.tsx" />
      {/* <Features /> */}
      <OthersCharges data-sentry-element="OthersCharges" data-sentry-source-file="page.tsx" />

      <Pricing data-sentry-element="Pricing" data-sentry-source-file="page.tsx" />
      <Guarantee bgColor="bg-white" data-sentry-element="Guarantee" data-sentry-source-file="page.tsx" />
      {/* <WhatWeDontDoHome /> */}
      <SocialProofCarousel title="Verified Testimonials" bgColor="bg-backgoundPlatinum" data-sentry-element="SocialProofCarousel" data-sentry-source-file="page.tsx" />
      <span id="pricing-section2"></span>
      <Pricing data-sentry-element="Pricing" data-sentry-source-file="page.tsx" />
      <Guarantee bgColor="bg-oxfordBlue" data-sentry-element="Guarantee" data-sentry-source-file="page.tsx" />
      <FAQ data-sentry-element="FAQ" data-sentry-source-file="page.tsx" />
      {/* <LearnMore /> */}
      <SocialLinks data-sentry-element="SocialLinks" data-sentry-source-file="page.tsx" />

      {/* success and error modal */}
      {!!successModal && <SuccessFailureDialog {...{
      isOpen: !!successModal,
      onOpenChange: setSuccessModal
    }} />}
      <ErrorDialog isOpen={isErrorDialogOpen} message={errorMessage || ''} onOpenChange={setIsErrorDialogOpen} data-sentry-element="ErrorDialog" data-sentry-source-file="page.tsx" />
    </div>;
}